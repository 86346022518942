import Auth from 'Auth';
import {Button, Form, Input} from 'antd';
import {useLoginMutation} from 'api/authSlice';
import axios from 'axios';
import {LOGIN_DONE_URL, LOGIN_URL, TRACK_BASE_URL, TRACK_LITE} from 'consts';
import {handleApiError} from 'errorHandler';
import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

const LoginPage = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/login' && !location.search && !TRACK_LITE) {
      axios.get(LOGIN_URL).then((response) => {
        window.location.href = response.data.url;
      });
    }
  }, []);

  if (TRACK_LITE) {
    return <CustomLogin />;
  } else if (location.pathname === '/login_done/') {
    return <LoginDone />;
  } else if (location.pathname === '/login_failed/') {
    return <LoginFailed />;
  }
  return <div>Redirecting to OneLogin...</div>;
};

export default LoginPage;

const CustomLogin = () => {
  const [form] = Form.useForm();

  const [login] = useLoginMutation();

  const handleSubmit = (e) => {
    Auth.logout(false);
    login({username: e.username, password: e.password})
      .unwrap()
      .then((response) => {
        Auth.passwordLogin(response);
        // Redirect to protected route or update state
        window.location.href = '/mfa';
      });
  };

  return (
    <Form
      style={{width: '500px', margin: 'auto'}}
      form={form}
      name="control-hooks"
      onFinish={handleSubmit}
    >
      <Form.Item
        label="Username"
        name="username"
        rules={[
          {
            required: true,
            message: 'Please enter a username.',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[
          {
            required: true,
            message: 'Please enter a password.',
          },
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

const LoginDone = () => {
  const location = useLocation();
  useEffect(() => {
    const paramsString = location;
    if (!paramsString) {
      return;
    }
    const params = new URLSearchParams(paramsString.search);
    const code = params.get('code');
    axios
      .post(LOGIN_DONE_URL, {
        code: code,
        redirect_uri: `${TRACK_BASE_URL}/login_done/`,
      })
      .then(
        (response) => {
          Auth.finishLogin(response.data);
        },
        (error) => {
          error.data ??= {};
          error.data.detail = 'Login error';
          handleApiError(error, 'Login error');
        }
      );
  }, []);
  return <div>Finishing login process. You should be redirected...</div>;
};

const LoginFailed = () => {
  useEffect(() => {
    Auth.logout();
  }, []);
  return <div>There was a problem accessing TRACK, please log in again.</div>;
};
