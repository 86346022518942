class Auth {
  constructor() {
    this.permittedPaths = ['', 'login', 'login_failed', 'mfa'];
    this.restrictedPaths = [
      'audits',
      'audit_rules',
      'audits_overview',
      'audits_issue_overview',
      'audits_school_summary',
      'data_portal',
      'data_report',
      'data_report_editor',
      'esoy_it_support',
      'hs_schedule',
      'login_done',
      'person',
      'school_setup',
      'search',
      'source_manager',
      'staff_roles_it_support',
      'staff_roles',
    ];

    // Initialize user permissions from localStorage
    this.permissions = [
      'access_to_data_portal',
      'access_to_data_portal_editor',
      'access_to_data_reports',
      'access_to_data_reports_editor',
      'access_to_esoy',
      'access_to_hs_schedule',
      'access_to_search',
      'access_to_source_manager',
      'access_to_source_manager_editor',
      'access_to_staff_account_status',
      'can_view_all_schools',
      'can_view_esoy_overview',
    ].reduce((acc, key) => {
      acc[key] = localStorage.getItem(key) === 'true';
      return acc;
    }, {});

    this.datadog_session_id = localStorage.getItem('datadog_session_id');
    this.ps_site_code = localStorage.getItem('ps_site_code');
    this.token = localStorage.getItem('token');
    this.user = this.getLocalItem('user');
  }

  isLoggedIn() {
    return !!this.token;
  }

  getPermissions() {
    return this.permissions;
  }

  finishLogin(data) {
    const permissions = {
      access_to_data_portal:
        data.access_to_data_portal || data.access_to_data_portal_editor || true, // TODO: remove last condition
      access_to_data_portal_editor: data.access_to_data_portal_editor || true, // TODO: remove last condition
      access_to_data_reports_editor: data.access_to_data_reports_editor,
      access_to_data_reports:
        data.access_to_data_reports || data.access_to_data_reports_editor,
      access_to_esoy: data.access_to_esoy,
      access_to_hs_schedule: data.access_to_hs_schedule,
      access_to_search: data.access_to_search,
      access_to_source_manager_editor: data.access_to_source_manager_editor,
      access_to_source_manager:
        data.access_to_source_manager || data.access_to_source_manager_editor,
      access_to_staff_account_status: data.access_to_staff_account_status,
      can_view_all_schools: data.can_view_all_schools,
      can_view_esoy_overview: data.can_view_esoy_overview,
    };

    this.setLocalItems({
      ...permissions,
      ps_site_code: data.ps_site_code,
      token: data.access_token,
      user: data.user_info,
    });

    this.permissions = permissions;

    const redirect = localStorage.getItem('redirect');
    if (redirect) {
      window.location.href = redirect;
      localStorage.removeItem('redirect');
    } else {
      window.location.href = '/';
    }
  }

  passwordLogin(data) {
    this.setLocalItems({token: data.access_token});
  }

  otpLogin(data) {
    this.setLocalItems({
      user: data.user_info,
      access_to_data_reports:
        data.access_to_data_reports || data.access_to_data_reports_editor,
      access_to_data_reports_editor: data.access_to_data_reports_editor,
    });
  }

  logout(redirect = true) {
    this.removeLocalItems(['token']);
    if (redirect) window.location.href = '/';
  }

  getUser() {
    const user = this.getLocalItem('user') || {};
    delete user['params'];
    user['datadog_session_id'] = this.getLocalItem('datadog_session_id');
    user['ps_site_code'] = this.getLocalItem('ps_site_code');
    user['token'] = this.getLocalItem('token');
    user['permissions'] = this.getPermissions();
    return user;
  }

  hasPermission(route) {
    const path = route.split('/')[1];

    // If the path is not in the restricted paths, then it is permitted
    if (!this.restrictedPaths.includes(path)) return true;

    const userLoggedIn = this.isLoggedIn();

    // Map the path to the permission
    // Paths not in the map are restricted to any logged in users
    const permissionMap = {
      data_portal: this.permissions.access_to_data_portal,
      data_report_editor: this.permissions.access_to_data_reports_editor,
      data_report: this.permissions.access_to_data_reports,
      esoy_it_support: this.permissions.can_view_esoy_overview,
      hs_schedule: this.permissions.access_to_hs_schedule,
      school_setup: this.permissions.access_to_esoy,
      search: this.permissions.access_to_search,
      source_manager: this.permissions.access_to_source_manager,
      staff_roles_it_support:
        this.permissions.access_to_staff_account_status &&
        this.permissions.can_view_esoy_overview,
      staff_roles: this.permissions.access_to_staff_account_status,
    };

    return path in permissionMap
      ? userLoggedIn && permissionMap[path]
      : userLoggedIn;
  }

  getToken() {
    return this.token ? `Token ${this.token}` : null;
  }

  // Helper methods
  getLocalItem(key) {
    const item = localStorage.getItem(key);
    try {
      return JSON.parse(item);
    } catch (e) {
      return item;
    }
  }

  setLocalItems(items) {
    Object.entries(items).forEach(([key, value]) => {
      const finalValue =
        typeof value === 'object' ? JSON.stringify(value) : value;
      localStorage.setItem(key, finalValue);
      this[key] = finalValue;
    });
  }

  removeLocalItems(keys) {
    keys.forEach((key) => {
      localStorage.removeItem(key);
      delete this[key];
    });
  }
}

const auth = new Auth();
export default auth;
