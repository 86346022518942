import {MenuFoldOutlined, MenuUnfoldOutlined} from '@ant-design/icons';
import Auth from 'Auth';
import {Alert, Button, Drawer, Tooltip} from 'antd';
import {useFetchSingleAggregateReportQuery} from 'api/reportsSlice';
import DataReport from 'components/dataReports/DataReport';
import DataReportEditor from 'components/dataReports/DataReportEditor';
import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

const DataReportPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const reportId = location.pathname.split('/')[2];
  const editorMode =
    (location.pathname.endsWith('/editor') ||
      location.pathname.startsWith('/data_report_editor')) &&
    Auth.permissions.access_to_data_reports_editor;

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [leftPosition, setLeftPosition] = useState('600px');
  const [errors, setErrors] = useState([]);

  const {data, isLoading} = useFetchSingleAggregateReportQuery(reportId, {
    skip: !reportId || reportId === 'new',
  });

  useEffect(() => {
    setLeftPosition(drawerVisible ? '600px' : '0%');
  }, [drawerVisible]);

  useEffect(() => {
    if ((!reportId || reportId === 'new') && data && editorMode) {
      navigate(`/data_report/${data.id}/editor`);
    }
  }, [data]);

  return (
    <>
      {editorMode && (
        <>
          <Tooltip title="Data Report Editor" placement="right">
            <Button
              onClick={() => setDrawerVisible(!drawerVisible)}
              type="primary"
              style={{
                top: '15%',
                height: '30%',
                left: leftPosition,
                position: 'fixed',
                zIndex: 1001,
                fontSize: '1.8em',
              }}
            >
              {drawerVisible ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
            </Button>
          </Tooltip>
          <Drawer
            title="Data Report Editor"
            placement="left"
            closable={true}
            onClose={() => setDrawerVisible(false)}
            open={drawerVisible}
            width={600}
            getContainer={false}
            style={{
              position: 'fixed',
              overflowY: 'auto',
              minHeight: 'calc(100% - 64px)',
              boxShadow: '0 9px 28px 8px rgba(0, 0, 0, 0.05)',
            }}
          >
            <DataReportEditor
              errors={errors}
              setErrors={setErrors}
              loading={isLoading}
            />
          </Drawer>
          {!data && (
            <Alert
              message={
                <div>
                  Please define a report in the editor.
                  <Button
                    type="primary"
                    onClick={() => setDrawerVisible(true)}
                    style={{display: 'inline-block', marginLeft: 10}}
                  >
                    Open Editor
                  </Button>
                </div>
              }
              type="info"
              style={{margin: 10}}
            />
          )}
        </>
      )}
      <DataReport loading={isLoading} />
    </>
  );
};

export default DataReportPage;
