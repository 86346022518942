import {DownloadOutlined, LeftOutlined} from '@ant-design/icons';
import Auth from 'Auth';
import {Alert, Button, Spin} from 'antd';
import {useFetchReportMetaQuery, useFetchReportQuery} from 'api/reportsSlice';
import axios from 'axios';
import DataTable from 'components/genericComponents/DataTable';
import useThrottle from 'components/genericComponents/Throttle';
import {TRACK_API_BASE_URL} from 'consts';
import {handleApiError} from 'errorHandler';
import React, {useCallback, useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';

const DataReport = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const reportId = location.pathname.split('/')[2];
  const editorMode =
    (location.pathname.endsWith('/editor') ||
      location.pathname.startsWith('/data_report_editor')) &&
    Auth.permissions.access_to_data_reports_editor;

  const [queryUrl, setQueryUrl] = useState();

  const {data: reportMeta, isLoading: loadingMeta} = useFetchReportMetaQuery(
    `${reportId}?draft=${editorMode}`,
    {
      skip: !reportId || reportId === 'new',
    }
  );
  const {
    data: reportData,
    isLoading: loadingData,
    isFetching,
  } = useFetchReportQuery(queryUrl, {
    skip: !queryUrl || queryUrl.startsWith('new'),
  });

  const throttledRequest = useThrottle(setQueryUrl);

  useEffect(() => {
    if (!reportMeta) return;
    if (params.toString().length) return;
    // if there are no query params, check if there are any default sort columns and add them
    const newParams = new URLSearchParams();
    const defaultSortColumns = reportMeta.columns.filter(
      (column) => column.sort
    );
    let sortParam = '';
    defaultSortColumns.forEach((column) => {
      sortParam += `${column.alias}:${column.sort},`;
    });
    if (sortParam) {
      newParams.set('sort', sortParam.slice(0, -1));
    }
    setParams(newParams.toString());
  }, [reportMeta, params]);

  useEffect(() => {
    const newQueryUrl = getQueryParams();
    if (newQueryUrl !== queryUrl) {
      throttledRequest(newQueryUrl);
    }
  }, [location.search]);

  const setParams = (newParams) => {
    if (newParams === params) {
      return;
    }
    navigate(`${location.pathname}?${newParams}`);
  };

  const getQueryParams = useCallback(() => {
    let url = `${reportId}?`;
    const params = new URLSearchParams(location.search);
    for (const entry of params.entries()) {
      if (entry[1]) {
        entry[0] = entry[0].replace('filter_', '');
        entry[0] = entry[0].replace('filter_', '');
        url += `${entry[0]}=${entry[1]}&`;
      }
    }
    if (editorMode) url += 'draft=true&';
    return url;
  }, [location.search, reportId]);

  const downloadReport = () => {
    if (!reportId) return;
    let url = queryUrl.replace(/page=\d+&page_size=\d+/, 'page_size=10000');
    axios({
      url: `${TRACK_API_BASE_URL}/data_reports/reports/csv/${url}`,
      method: 'GET',
      responseType: 'blob',
      // important
      headers: {
        Authorization: Auth.getToken(),
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const reportName = reportMeta.name.replace(/ /g, '_').toLowerCase();
        const fileName = `${reportName}.csv`;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        error.data ??= {};
        error.data.detail = 'Error downloading report';
        handleApiError(error);
      });
  };

  return (
    <>
      <div
        className="flex-row"
        style={{justifyContent: 'space-between', margin: '0 10px'}}
      >
        <Link to={`/data_report/${editorMode ? 'editor' : ''}`}>
          <LeftOutlined />
          Back to Reports
        </Link>
        {reportData?.report && (
          <Button onClick={() => downloadReport()}>
            <DownloadOutlined />
            Download
          </Button>
        )}
      </div>
      <h1>{reportMeta?.name}</h1>
      <Spin spinning={loadingData || loadingMeta || isFetching}>
        <div style={{margin: 10}}>
          {reportMeta?.long_description ? (
            <h4>{reportMeta.long_description}</h4>
          ) : null}
          <DataTable
            params={params}
            setParams={setParams}
            data={reportData}
            meta={reportMeta}
            bordered
            size="small"
            key={
              JSON.stringify(reportData) +
              JSON.stringify(reportMeta) +
              loadingData +
              loadingMeta
            }
          />
          {editorMode && reportData?.sql && (
            <Alert
              message="Generated SQL Query:"
              type="info"
              style={{margin: '10px 0'}}
              description={
                <div>
                  <p>{reportData.sql}</p>
                  <Button
                    type="primary"
                    onClick={() =>
                      navigator.clipboard.writeText(reportData.sql)
                    }
                  >
                    Copy to Clipboard
                  </Button>
                </div>
              }
            />
          )}
        </div>
      </Spin>
    </>
  );
};

export default DataReport;
