import Auth from 'Auth';
import DataPortalDisplay from 'components/dataPortal/DataPortalDisplay';
import PageDrawer from 'components/dataPortal/PageDrawer';
import React from 'react';
import {useLocation} from 'react-router-dom';

const DataPortalPage = () => {
  const location = useLocation();
  const editorMode =
    location.pathname.endsWith('/editor') &&
    Auth.permissions.access_to_data_portal_editor;

  return (
    <div>
      <div className="flex-column">
        {editorMode && <PageDrawer />}
        <DataPortalDisplay editorMode={editorMode} />
      </div>
    </div>
  );
};

export default DataPortalPage;
