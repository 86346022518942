import Auth from 'Auth';
import {Breadcrumb} from 'antd';
import {useFetchSeedDataQuery} from 'api/seedsSlice';
import SeedForm from 'components/seeds/SeedForm';
import TableEditor from 'components/seeds/TableEditor';
import React from 'react';
import {Link, useLocation} from 'react-router-dom';

const SeedEditorPage = () => {
  const location = useLocation();
  const seedId = location.pathname.split('/')[2];
  const editorMode =
    location.pathname.endsWith('/editor') &&
    Auth.permissions.access_to_source_manager_editor;

  const {data: seedData} = useFetchSeedDataQuery(seedId, {
    skip: !seedId,
  });

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={`/source_manager/${editorMode ? 'editor' : ''}`}>
            Source Manager
          </Link>
        </Breadcrumb.Item>
        {seedData?.metadata?.path?.map((path) => (
          <Breadcrumb.Item key={path}>{path}</Breadcrumb.Item>
        ))}
        <Breadcrumb.Item>
          <Link to={`/source_manager/${seedId}`}>{seedId}</Link>
        </Breadcrumb.Item>
        {editorMode && <Breadcrumb.Item>Editor</Breadcrumb.Item>}
      </Breadcrumb>
      <h1>Track-Managed Sources Editor</h1>
      <h1>{seedId || 'Playground'}</h1>
      <div className="flex-column">
        {editorMode && <SeedForm seedId={seedId} />}

        <TableEditor seedId={seedId} />
      </div>
    </div>
  );
};

export default SeedEditorPage;
